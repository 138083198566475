export const EXPERIMENTS = {
  ADDITIONAL_BUTTON_SETTINGS_VIEWER: 'specs.membership.AdditionalButtonSettingsViewer',
  ADDITIONAL_BUTTON_SETTINGS_EDITOR: 'specs.membership.AdditionalButtonSettingsEditor',
  SETTINGS_PAGINATED_PLANS: 'specs.membership.SettingsPaginatedPlans',
  DYNAMIC_TAGLINE: 'specs.membership.PricingPlansTpaDynamicTagline',
  RESTYLED_CHECKOUT: 'specs.membership.RestyledCheckout',
  NEW_ADDONS_DESIGN: 'specs.membership.NewAddonsDesign',
  ENABLE_COUPONS: 'specs.membership.EnableCoupons',
  SINGLE_PLAN_ADDON: 'specs.membership.SinglePlanAddon',
};
