import intersection from 'lodash/intersection';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { ISettingsContextValue, ISettingsValue } from '@wix/yoshi-flow-editor/tpa-settings';
import { PLANS_QUERY_PAGE_SIZE } from '../../constants';
import { HIGHGLIGHTED_MOCK_PLAN_ID } from '../../fixtures';
import { PlansQuery } from '../../services';
import { isTruthy } from '../../utils/isTruthy';
import { removePlansWithIds, getIds } from '../../utils/plan';
import { PackagePickerSettingsParams } from './settingsParams';

export type ReadSettings = Pick<ISettingsContextValue, 'get'>;
export type WriteSettings = Pick<ISettingsContextValue, 'set'>;
export type ReadWriteSettings = ReadSettings & WriteSettings;

export class SettingsReader {
  constructor(public settings: ReadSettings, public params: PackagePickerSettingsParams) {}

  get<K extends keyof PackagePickerSettingsParams>(key: K) {
    return this.settings.get(this.params[key]);
  }

  asArray<K extends keyof PackagePickerSettingsParams>(key: K): string[] {
    return (this.settings.get(this.params[key]) as string).split(',').filter(isTruthy);
  }
}

class SettingsReaderWriter extends SettingsReader {
  constructor(public settings: ReadWriteSettings, public params: PackagePickerSettingsParams) {
    super(settings, params);
  }

  set<K extends keyof PackagePickerSettingsParams>(key: K, value: ISettingsValue<PackagePickerSettingsParams[K]>) {
    return this.settings.set(this.params[key], value);
  }
}

export class DefaultSettingsAdapter extends SettingsReaderWriter {
  constructor(public settings: ReadWriteSettings, public params: PackagePickerSettingsParams) {
    super(settings, params);
  }

  getHighlightedPlanId(plans: PublicPlan[], showDemoHighlight?: boolean) {
    const showHighlightedPlan = this.get('showHighlightedPlan');
    const highlightedPlanId = this.get('highlightedPlan');

    const primaryPlanId = plans.find((plan) => plan.primary)?.id;

    if (showHighlightedPlan && highlightedPlanId) {
      return highlightedPlanId;
    }

    if (showHighlightedPlan && !highlightedPlanId && primaryPlanId) {
      return primaryPlanId;
    }

    return (
      plans.find((p) => p.id === HIGHGLIGHTED_MOCK_PLAN_ID)?.id ||
      (showDemoHighlight && (plans[1]?.id || plans[0]?.id)) ||
      ''
    );
  }

  getHiddenPlanIds(plans: PublicPlan[]): string[] {
    return intersection(getIds(plans), this.asArray('hiddenPlans'));
  }

  getOrderedPlans(plans: PublicPlan[]): PublicPlan[] {
    const hiddenPlans = this.getHiddenPlanIds(plans);
    const planOrder = this.asArray('planOrder');
    const ordered = planOrder.map((id) => plans.find((p) => p.id === id)).filter(isTruthy);
    const unordered = removePlansWithIds(plans, planOrder);
    const visible = removePlansWithIds([...ordered, ...unordered], hiddenPlans);
    if (this.get('usePagination')) {
      return visible;
    }
    return visible.slice(0, PLANS_QUERY_PAGE_SIZE);
  }

  countVisiblePlans(plans: PublicPlan[]): number {
    const hiddenPlans = this.getHiddenPlanIds(plans);
    return removePlansWithIds(plans, hiddenPlans).length;
  }

  hidePlan(id: string) {
    const hiddenPlans = this.asArray('hiddenPlans');
    this.set('hiddenPlans', hiddenPlans.concat(id).join(','));
  }

  unhidePlan(id: string) {
    const hiddenPlans = this.asArray('hiddenPlans').filter((hiddenId) => id !== hiddenId);
    this.set('hiddenPlans', hiddenPlans.join(','));
  }

  runMigrations(_plans: PublicPlan[]) {}

  /**
   * Method is overwritten in PackagePickerSettingsAdapter.
   * Default implementation does nothing
   */
  async clearPrimaryPlan(plans: PublicPlan[]): Promise<PublicPlan[]> {
    return plans;
  }

  setHighlightedPlan(plans: PublicPlan[], id: string) {
    this.set('highlightedPlan', plans.find((plan) => plan.id === id)?.id ?? '');
  }

  shouldFetchVisiblePlans(loadedPlans: PublicPlan[]): boolean {
    const hiddenPlans = this.asArray('hiddenPlans');
    return hiddenPlans.length > 0 && removePlansWithIds(loadedPlans, hiddenPlans).length < PLANS_QUERY_PAGE_SIZE;
  }

  getVisiblePlansQuery(): PlansQuery {
    return { exclude: this.asArray('hiddenPlans') };
  }
}
